import React, { ReactNode } from "react";
import { InstagramEmbed } from "react-social-media-embed";
import { MDXProvider } from "@mdx-js/react"
import { Box, Typography, makeStyles } from "@material-ui/core";

import { Card } from "../Card";
import { Blockquote, Content, ContentColumns } from "../Content";
import { Anchor, ButtonLink } from "../Link";



type MarkdownProps = {
	children: ReactNode
}

export function MarkdownComponentsProvider(props: MarkdownProps) {
	const { children } = props;
	
	return (
		<MDXProvider components={components}>{children}</MDXProvider>
	);
}

const a = ({ href, children }: any) => (<Anchor to={href}>{children}</Anchor>);
const h2 = ({ children }: any) => (<Typography variant="h2" className={useStyles().margin}>{children}</Typography>);
const p = ({ children }: any) => (<Typography variant="body1" className={useStyles().margin}>{children}</Typography>);

const components = {
	a,
	p,
	blockquote: Blockquote,
	Box,
	ButtonLink,
	Card,
	Content,
	ContentColumns,
	InstagramEmbed,
	h2,
};




const useStyles = makeStyles(theme => ({
	margin: {
		marginTop: '0.9em',
		marginBottom: '0.9em',
	}
}))