import React, { ReactNode } from "react";
import clsx from "clsx";
import { makeStyles, Paper } from "@material-ui/core";

import { useScrollPosition } from "../../utils/useScrollPosition";
import { useIsDesktopBreakpoint } from "../../utils/breakpoint";



export type ContentSidePanelProps = {
	children: ReactNode
}


export const ContentSidePanel = (props: ContentSidePanelProps) => {
	const classes = useStyles();
	const { children } = props;
	
	const isDesktop = useIsDesktopBreakpoint();
	const scrollStart = 100;
	const scrollStop = 200;
	const scroll = useScrollPosition(scrollStop);
	
	const progress = isDesktop ? 1 - ((Math.max(scroll, scrollStart) - scrollStart) / (scrollStop - scrollStart)) : 0;
	const easingProgress = easeInOutQuad(progress);
	const translateY = - easingProgress * 63;
	const paperStyle = {transform: `translateY(${translateY}%)`};
	
	const isTop = progress > 0;
	const elevation = isTop ? 4 : 1;
	
	return (
		<Paper className={clsx(classes.info, isTop && classes.info__top)} style={paperStyle} elevation={elevation}>
			{children}
		</Paper>
	);
};



const useStyles = makeStyles(theme => ({
	info: {
		position: 'sticky',
		top: 130,
		background: theme.palette.grey["200"],
	},
	info__top: {
		zIndex: theme.zIndex.appBar,
	}
}));


const easeInOutQuad = (t: number) => t <.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t;
