import {makeStyles} from "@material-ui/core/styles";
import React, {ReactNode} from "react";

export interface BlockquoteProps {
    children: ReactNode;
}

export function Blockquote(props: BlockquoteProps) {
    const { children } = props;
    const classes = useStyles();
    return (
        <blockquote className={classes.blockquote}>
            {children}
        </blockquote>
    );
}

const useStyles = makeStyles(theme => {
    const breakpoint = theme.breakpoints.up('sm');
    return ({
        blockquote: {
            margin: 0,
            position: 'relative',
            [breakpoint]: {
            },
            '& p:first-child': {
                textIndent: '1.5rem',
                [breakpoint]: {
                    // textIndent: 'initial',
                },
            },
            '& footer': {
                fontFamily: theme.typography.h1.fontFamily,
                fontWeight: 'bold',
                // fontSize: '0.9rem',
                textAlign: 'right',
                marginRight: '1.5rem',
                [breakpoint]: {
                    marginRight: '3.5rem',
                }
            },
            '&:before, &:after': {
                display: 'block',
                position: 'absolute',
                color: theme.palette.grey["200"],
                lineHeight: 1,
                fontSize: '3.4rem',
                fontFamily: theme.typography.h1.fontFamily,
                [breakpoint]: {
                    fontSize: '5rem',
                },
            },
            '&:before': {
                content: '\'“\'',
                left: '-0.5rem',
                top: '-0.3rem',
                [breakpoint]: {
                    left: '-1rem',
                    top: '-1.0rem',
                },
            },
            '&:after': {
                content: '\'„\'',
                bottom: '0.2rem',
                right: '-0.5rem',
                [breakpoint]: {
                    right: 0,
                    bottom: 0,
                },
            },
        },
    });
});