import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";



export interface ContentProps {
	children: React.ReactNode,
	side?: React.ReactNode,
}


export const Content = (props: ContentProps) => {
	const { children, side } = props;
	const classes = useStyles();
	return (
		<Grid container spacing={4}>
			{side && (
				<Grid item xs={12} md={5} lg={4} className={classes.side}>
					{side}
				</Grid>
			)}
			<Grid item xs={12} md={7} lg={8}>
				<Typography variant="body1" component="div" className={classes.content}>
					{children}
				</Typography>
			</Grid>
		</Grid>
	);
};


const useStyles = makeStyles(theme => ({
	side: {
		[theme.breakpoints.up('md')]: {
			order: 1,
		},
	},
	content: {
		'& .gatsby-resp-image-wrapper': {
			marginLeft: `${-theme.spacing(2)}px!important`,
			marginRight: `${-theme.spacing(2)}px!important`,
			[theme.breakpoints.up('sm')]: {
				marginLeft: `${-theme.spacing(1)}px!important`,
				marginRight: `${-theme.spacing(1)}px!important`,
			},
			[theme.breakpoints.up('xl')]: {
				marginLeft: `${-theme.spacing(3)}px!important`,
				marginRight: `${-theme.spacing(1)}px!important`,
			},
		}
	}
}));
