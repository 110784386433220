import { Theme, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";



export const desktopBreakpoint = (theme: Theme) => theme.breakpoints.up('md');


export const useIsDesktopBreakpoint = (): boolean => {
	const theme = useTheme();
	return useMediaQuery(desktopBreakpoint(theme));
}
