// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-aktuality-jesen-mdx": () => import("./../../../src/pages/aktuality/jesen.mdx" /* webpackChunkName: "component---src-pages-aktuality-jesen-mdx" */),
  "component---src-pages-aktuality-leto-mdx": () => import("./../../../src/pages/aktuality/leto.mdx" /* webpackChunkName: "component---src-pages-aktuality-leto-mdx" */),
  "component---src-pages-aktuality-psie-zaprahy-mdx": () => import("./../../../src/pages/aktuality/psie-zaprahy.mdx" /* webpackChunkName: "component---src-pages-aktuality-psie-zaprahy-mdx" */),
  "component---src-pages-aktuality-remeselnicky-mdx": () => import("./../../../src/pages/aktuality/remeselnicky.mdx" /* webpackChunkName: "component---src-pages-aktuality-remeselnicky-mdx" */),
  "component---src-pages-darcekove-poukazy-index-mdx": () => import("./../../../src/pages/darcekove-poukazy/index.mdx" /* webpackChunkName: "component---src-pages-darcekove-poukazy-index-mdx" */),
  "component---src-pages-firmy-index-mdx": () => import("./../../../src/pages/firmy/index.mdx" /* webpackChunkName: "component---src-pages-firmy-index-mdx" */),
  "component---src-pages-horske-trojkolky-index-mdx": () => import("./../../../src/pages/horske-trojkolky/index.mdx" /* webpackChunkName: "component---src-pages-horske-trojkolky-index-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-kontakt-index-mdx": () => import("./../../../src/pages/kontakt/index.mdx" /* webpackChunkName: "component---src-pages-kontakt-index-mdx" */),
  "component---src-pages-kontakt-odoslany-mdx": () => import("./../../../src/pages/kontakt/odoslany.mdx" /* webpackChunkName: "component---src-pages-kontakt-odoslany-mdx" */),
  "component---src-pages-lyziarske-zajazdy-index-mdx": () => import("./../../../src/pages/lyziarske-zajazdy/index.mdx" /* webpackChunkName: "component---src-pages-lyziarske-zajazdy-index-mdx" */),
  "component---src-pages-nas-pribeh-index-mdx": () => import("./../../../src/pages/nas-pribeh/index.mdx" /* webpackChunkName: "component---src-pages-nas-pribeh-index-mdx" */),
  "component---src-pages-obchodne-podmienky-index-mdx": () => import("./../../../src/pages/obchodne-podmienky/index.mdx" /* webpackChunkName: "component---src-pages-obchodne-podmienky-index-mdx" */),
  "component---src-pages-ochrana-osobnych-udajov-index-mdx": () => import("./../../../src/pages/ochrana-osobnych-udajov/index.mdx" /* webpackChunkName: "component---src-pages-ochrana-osobnych-udajov-index-mdx" */),
  "component---src-pages-okolie-index-mdx": () => import("./../../../src/pages/okolie/index.mdx" /* webpackChunkName: "component---src-pages-okolie-index-mdx" */),
  "component---src-pages-restauracia-index-mdx": () => import("./../../../src/pages/restauracia/index.mdx" /* webpackChunkName: "component---src-pages-restauracia-index-mdx" */),
  "component---src-pages-rezervacia-index-mdx": () => import("./../../../src/pages/rezervacia/index.mdx" /* webpackChunkName: "component---src-pages-rezervacia-index-mdx" */),
  "component---src-pages-rezervacia-odoslana-mdx": () => import("./../../../src/pages/rezervacia/odoslana.mdx" /* webpackChunkName: "component---src-pages-rezervacia-odoslana-mdx" */),
  "component---src-pages-svadby-a-rodinne-oslavy-index-mdx": () => import("./../../../src/pages/svadby-a-rodinne-oslavy/index.mdx" /* webpackChunkName: "component---src-pages-svadby-a-rodinne-oslavy-index-mdx" */),
  "component---src-pages-ubytovanie-2-lozkova-izba-standard-mdx": () => import("./../../../src/pages/ubytovanie/2-lozkova-izba-standard.mdx" /* webpackChunkName: "component---src-pages-ubytovanie-2-lozkova-izba-standard-mdx" */),
  "component---src-pages-ubytovanie-3-lozkova-izba-standard-mdx": () => import("./../../../src/pages/ubytovanie/3-lozkova-izba-standard.mdx" /* webpackChunkName: "component---src-pages-ubytovanie-3-lozkova-izba-standard-mdx" */),
  "component---src-pages-ubytovanie-apartman-komfort-mdx": () => import("./../../../src/pages/ubytovanie/apartman-komfort.mdx" /* webpackChunkName: "component---src-pages-ubytovanie-apartman-komfort-mdx" */),
  "component---src-pages-ubytovanie-bezbarierova-izba-premium-mdx": () => import("./../../../src/pages/ubytovanie/bezbarierova-izba-premium.mdx" /* webpackChunkName: "component---src-pages-ubytovanie-bezbarierova-izba-premium-mdx" */),
  "component---src-pages-ubytovanie-index-mdx": () => import("./../../../src/pages/ubytovanie/index.mdx" /* webpackChunkName: "component---src-pages-ubytovanie-index-mdx" */),
  "component---src-pages-ubytovanie-izba-premium-mdx": () => import("./../../../src/pages/ubytovanie/izba-premium.mdx" /* webpackChunkName: "component---src-pages-ubytovanie-izba-premium-mdx" */),
  "component---src-pages-ubytovanie-rodinny-apartman-mdx": () => import("./../../../src/pages/ubytovanie/rodinny-apartman.mdx" /* webpackChunkName: "component---src-pages-ubytovanie-rodinny-apartman-mdx" */)
}

