import React, { ReactNode } from "react";
import clsx from "clsx";
import { Box, Card as MuiCard, CardContent, CardMedia, Grid, makeStyles, Typography } from "@material-ui/core";

import { Image } from "../Image";
import { CardActionAreaLink, LinkProps } from "../Link";



export type CardProps = {
	variant?: "vertical" | "horizontal" | "horizontal-content";
	image: string,
	imagePosition?: string | undefined,
	title: ReactNode,
	subtitle?: ReactNode,
	children: ReactNode,
} & Partial<LinkProps>;



export const Card = (props: CardProps) => {
	const { variant = "vertical", image, imagePosition = '50% 50%', title, subtitle, children, to, external } = props;
	const classes = usePageCardStyles();

	const horizontal = (variant === 'horizontal' || variant === 'horizontal-content');
	
	const cardMedia = (
		<CardMedia className={classes.media}>
			<Image
				file={image}
				className={classes.media_bg}
				objectFit="cover"
				objectPosition={imagePosition}
			/>
		</CardMedia>
	);
	
	const cardContent = (
		<CardContent>
			<Typography gutterBottom variant="h5" component="h2">
				{title}
			</Typography>
			{subtitle && (
				<Box mb={1}><Typography variant="subtitle2">{subtitle}</Typography></Box>
			)}
			<Typography variant="body2" color="textSecondary" component="p">
				{children}
			</Typography>
		</CardContent>
	);

	const colsMedia = variant === 'horizontal-content' ? 4 : 8;
	const colsContent = variant === 'horizontal-content' ? 8 : 4;
	
	const inner = horizontal ? (
			<Grid container spacing={2}>
				<Grid item xs={12} sm={colsMedia} >
					{cardMedia}
				</Grid>
				<Grid item xs={12} sm={colsContent}>
					{cardContent}
				</Grid>
			</Grid>
		) : (
			<>
				{cardMedia}
				{cardContent}
			</>
		);
	return (
		<MuiCard className={clsx(
			classes.card,
			to && classes.card__link,
			horizontal && classes.card__horizontal
		)} elevation={0}>
			{!to ? inner : (
				<CardActionAreaLink to={to} external={external}>
					{inner}
				</CardActionAreaLink>
			)}
		</MuiCard>
	)
};


const usePageCardStyles = makeStyles(theme => ({
	card: {
		background: "transparent",
		textAlign: 'left',
		transform: 'none',
	},
	card__link: {
		transition: theme.transitions.create('transform', { duration: 1000, easing: 'cubic-bezier(0.075, 0.82, 0.165, 1)' }),
		'&:hover':  {
			transform: 'translateY(-4px)',
			transition: theme.transitions.create('transform', { duration: 30, easing: theme.transitions.easing.easeIn }),
		},
	},
	card__horizontal: {
		textAlign: 'left',
	},
	media: {
		height: 240,
	},
	media_bg: {
		height: "100%",
	}
}));
