import React from "react"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import skLocale from "date-fns/locale/sk";
import DateFnsUtils from "@date-io/date-fns"
import { MarkdownComponentsProvider } from "../Markdown";



class LocalizedUtils extends DateFnsUtils {

	getCalendarHeaderText(date: Date): string {
		return this.format(date, 'LLLL yyyy');
	}

}



export interface RootWrapperProps {
	element: React.ReactNode,
}


export const RootWrapper = (props: RootWrapperProps) => {
	const { element } = props;
	return (
		<MuiPickersUtilsProvider
			utils={LocalizedUtils}
			locale={skLocale}
		>
			<MarkdownComponentsProvider>
				{element}
			</MarkdownComponentsProvider>
		</MuiPickersUtilsProvider>
	);
};
