import React, {ReactNode} from "react";
import { Grid } from "@material-ui/core";

export type ContentColumnsProps = {
	children: ReactNode[],
}

export const ContentColumns = (props: ContentColumnsProps) => {
	const { children } = props;
	return (
		<Grid container spacing={2}>
			{children.map((node, index) => (
				<Grid item xs={12} sm={4} key={index}>
					{node}
				</Grid>
			))}
		</Grid>
	);
};
