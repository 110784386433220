import { useState, useLayoutEffect } from "react"

const isBrowser = typeof window !== `undefined`;


export function useScrollPosition(max: number | undefined = undefined): number {
	const [position, setScrollPosition] = useState<number>(getScrollPosition(max));

	useLayoutEffect(() => {
		let lastPosition = position;

		function updateIfChanged(): boolean {
			const newPosition = getScrollPosition(max);
			if (newPosition !== lastPosition) {
				lastPosition = newPosition;
				setScrollPosition(newPosition);
				return true;
			}
			return false;
		}

		let running = false;
		function run() {
			running = true;
			const changed = updateIfChanged();
			if (changed) {
				requestAnimationFrame(run);
			} else {
				running = false;
			}
		}

		function handleScroll() {
			if (!running) {
				run();
			}
		}

		if (isBrowser && max !== 0) {
			window.addEventListener('scroll', handleScroll);
			return () => window.removeEventListener('scroll', handleScroll);
		}
	}, [setScrollPosition, max]);

	return position;
}


function getScrollPosition(max: number | undefined = undefined): number {
	let result = isBrowser ? window.pageYOffset : 0;
	if (max !== undefined && result > max) {
		return max;
	}
	return result;
}
