import { ThemeOptions, createTheme, responsiveFontSizes } from "@material-ui/core"


const fontFamilyDefault = 'Montserrat, sans-serif';
const fontFamilyHeading = 'Playfair Display, serif';


const typography: ThemeOptions["typography"] = {
	fontFamily: fontFamilyDefault,
	h1: { fontFamily: fontFamilyHeading, fontSize: '4.0rem', fontWeight: 500 },
	h2: { fontFamily: fontFamilyHeading, fontSize: '2.5rem' },
	h3: { fontFamily: fontFamilyHeading },
	h4: { fontFamily: fontFamilyHeading },
	h5: { fontFamily: fontFamilyHeading },
	h6: { fontFamily: fontFamilyHeading },
	body1: { lineHeight: 1.6 },
};


export const lightTheme = responsiveFontSizes(createTheme({
	palette: {
		background: {
			default: '#fff',
		},
	},
	typography,
}), {factor: 3.5});


export const darkTheme = responsiveFontSizes(createTheme({
	typography,
	palette: {
		type: 'dark',
	}
}), {factor: 1});
