import React from "react";
import Img, { GatsbyImageWithIEPolyfillProps } from 'gatsby-image/withIEPolyfill';

import { useFluidImage } from "../../utils/useFluidImage";



export interface ImageProps extends Omit<GatsbyImageWithIEPolyfillProps, "fluid" | "fixed"> {
	file: string,
}


export const Image = React.memo((props: ImageProps) => {

	const { file, ...rest } = props;
	const imageData = useFluidImage(file);

	return (
		<Img
			fluid={imageData}
			{...rest}
		/>
	);
});
