import {RootWrapper} from "./src/components/RootWrapper";


export const wrapRootElement = RootWrapper;


export const onClientEntry = () => {
	// IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
	if (typeof window.IntersectionObserver === 'undefined') {
		/* eslint-disable no-unused-expressions */
		import('intersection-observer');
	}
};
