import React, { forwardRef, memo, ReactNode, useMemo } from "react";
import { Link as GatsbyLink } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics";



export type LinkProps = {
	to: string,
	external?: boolean | undefined
	activeClassName?: string;
	children: ReactNode;
}


export const Link = memo(forwardRef<any, LinkProps>(
	(props, ref) => {
		const { to, external, activeClassName, ...rest } = props;
		const ext = external !== undefined ? external : to.match(/^[a-z]+:/i);

		if (ext) {
			return (
				<span ref={ref}>
					<OutboundLink
						href={to}
						{...rest}
					/>
				</span>
			);
		}

		return (
			<GatsbyLink
				ref={ref}
				to={to}
				activeClassName={activeClassName}
				partiallyActive={to !== '/'}
				{...rest}
			/>
		);
	}
));



export const wrapLink = <P extends {}>(Component: any, extraProps: any = {}) =>
	forwardRef<any, P>(
		(props: any, ref) => {
			const { to, external, activeClassName, ...rest } = props;
			
			const component = useMemo(
				() => forwardRef(
					(itemProps: any, ref) => (
						<Link
							ref={ref}
							to={to}
							external={external}
							activeClassName={activeClassName}
							{...itemProps}
						/>
					)),
				[to, external, activeClassName],
			);
			
			return (
				<Component
					ref={ref}
					component={component}
					{...rest}
					{...extraProps}
				/>
			);
		}
	);
