import { graphql, useStaticQuery } from "gatsby";
import { useMemo } from "react";



export const useFluidImage = (src: string) => {
	const data = useStaticQuery(graphql`
			query {
				allFile( filter: { internal: { mediaType: { regex: "/jpeg/" } } } ) {
					nodes {
						relativePath
						childImageSharp {
							fluid(maxWidth: 3840, quality: 90) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
			}
		`);

	return useMemo(() => {
		const node = data.allFile.nodes.find(({ relativePath }: any) => src === relativePath);
		return node && node.childImageSharp.fluid;
	}, [data, src]);

};

