import React from "react";
import {
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText, makeStyles,
	Tooltip,
} from "@material-ui/core";
import ExploreIcon from "@material-ui/icons/Explore";
import LaunchIcon from "@material-ui/icons/Launch";
import EmailIcon from "@material-ui/icons/Email";
import CallIcon from "@material-ui/icons/Call";
import InfoIcon from "@material-ui/icons/Info";

import { IconButtonLink, ListItemButtonLink } from "../Link";
import { ContentSidePanel } from "./ContentSidePanel";



// todo: move to cms
const email = 'info@studnicky.sk';
const phone = '+421 915 656 037';



export const ContentSidePanelContact = () => {
	const classes = useStyles();
	
	return (
		<ContentSidePanel>
			<List component="nav">
				<ListItem>
					<ListItemIcon><ExploreIcon /></ListItemIcon>
					<ListItemText
						primary={(<>
							Hotel Studničky<br />
							942/2, 05917 Vernár<br />
							Slovensko
						</>)}
					/>
					
					<ListItemSecondaryAction>
						<Tooltip placement="top" title="Zobraziť v aplikácii Google Mapy">
							<IconButtonLink to="https://goo.gl/maps/QhJPtxniU2WMQFFc9">
								<LaunchIcon />
							</IconButtonLink>
						</Tooltip>
					</ListItemSecondaryAction>
				</ListItem>
				
				<ListItem className={classes.secondary}>
					<ListItemText inset secondary={(<>
						48°55'26.8"N 20°15'39.1"E
					</>)} />
				</ListItem>
				
				<ListItemButtonLink to={`mailto:${email}`}>
					<ListItemIcon><EmailIcon /></ListItemIcon>
					<ListItemText>
						{email}
					</ListItemText>
				</ListItemButtonLink>
				
				<ListItemButtonLink to={`tel:${phone}`}>
					<ListItemIcon><CallIcon /></ListItemIcon>
					<ListItemText>{phone}</ListItemText>
				</ListItemButtonLink>
				
				<Divider />
				
				<ListItem>
					<ListItemIcon>
						<Tooltip placement="top" title="Fakturačné údaje">
							<InfoIcon />
						</Tooltip>
					</ListItemIcon>
					<ListItemText
						primary={(<>
							Ján Alexa - SAP<br />
							Pod Úbočou 213/18,<br />
							05917 Vernár
						</>)}>
					</ListItemText>
				</ListItem>
				
				<ListItem className={classes.secondary}>
					<ListItemText
						inset
						secondaryTypographyProps={{component: 'div'} as any}
						secondary={(
							<dl>
								<dt>IČO:</dt>
								<dd>35117851</dd>
								<dt>DIČ:</dt>
								<dd>1022831315</dd>
								<dt>IČ DPH:</dt>
								<dd>SK1022831315</dd>
							</dl>
						)}
					/>
				</ListItem>
				
				{/*<ListItem>*/}
				{/*	<ListItemIcon><AccountBalanceIcon /></ListItemIcon>*/}
				{/*	<ListItemText>SK31 1200 0000 1987 4263 7541</ListItemText>*/}
				{/*</ListItem>*/}
			</List>
		</ContentSidePanel>
	);
};


const useStyles = makeStyles(theme => ({
	secondary: {
		marginTop: -theme.spacing(2),
		
		"& dl": {
			margin: 0,
		},
		"& dt": {
			marginTop: theme.spacing(0.5),
		},
		"& dd": {
			marginTop: "-1.4em", // line spacing
			marginLeft: 80,
		},
	}
}));
