import React from "react";
import {
	Divider,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	makeStyles
} from "@material-ui/core";

import { ContentSidePanel } from "./ContentSidePanel";



export const ContentSidePanelRestauracia = () => {
	const classes = useStyles();
	return (
		<ContentSidePanel>
			<List component="dl" className={classes.dl}>
				<ListSubheader component="dt" className={classes.dt}>Otváracie hodiny</ListSubheader>
				<ListItem component="dd" className={classes.dd}><ListItemText>10:00 - 21:00</ListItemText></ListItem>
				
				<Divider className={classes.divider} />
				
				<ListSubheader component="dt" className={classes.dt}>Raňajky</ListSubheader>
				<ListItem component="dd" className={classes.dd}><ListItemText>07:30 - 09:30</ListItemText></ListItem>
				
				<ListSubheader component="dt" className={classes.dt}>Obed</ListSubheader>
				<ListItem component="dd" className={classes.dd}><ListItemText>11:30 - 14:00</ListItemText></ListItem>
				
				<ListSubheader component="dt" className={classes.dt}>Večera</ListSubheader>
				<ListItem component="dd" className={classes.dd}><ListItemText>18:00 - 20:00</ListItemText></ListItem>
			</List>
		</ContentSidePanel>
	);
};

const useStyles = makeStyles(theme => ({
	dl: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	dt: {
		width: '50%',
	},
	dd: {
		width: '50%',
		margin: 0,
	},
	divider: {
		width: '100%',
	},
}));
